.suggestions {
    max-height: 75vh;
    overflow: auto;
    position: absolute;
    z-index: 1500;
    cursor: pointer;
}

.suggestions-input {
    border-right-width: 0;
}

.clear-btn {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: transparent !important;
    color: black !important;
    border-left-width: 0;
    border-color: #ced4da !important;
}

.clear-btn:active {
    background-color: #ced4da !important;
}
 

.clear-btn:disabled {
    background-color: aliceblue !important;
}