.row-key {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.8rem;
  font-style: italic;
}

.row-value {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
}

.sidebar {
  margin-left: 5px;
}

.sidebar-button {
  position: fixed;
  top: 300px;
  right: -103px;
  width: 145px;
  border: 1px solid black;
  transform: rotate(90deg);
  border-top-right-radius: 0%;
  border-top-left-radius: 0%;
  z-index: 1500;
}

.sidebar-button:hover {
  background-color: lightgray;
}

.sidebar-button-text {
  margin-right: 25px;
}

.nav-link {
  cursor: pointer
}