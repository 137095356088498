/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.fit-content {
  max-width: fit-content;
}

main {
  min-height: calc(100vh - 160px) !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
}

.mw-120-px {
  max-width: 120px;
}

.shipdata-map-container{
  width: '100vw';
  height: calc(100vh - 290px);
}

.miw-140-px {
  min-width: 140px;
}

.custom-table {
  scroll-behavior: smooth;
}

.custom-table th {
  top: 0;
  text-align: center;
}

.custom-table::-webkit-scrollbar {
  width: 0.8em;
  height: 0.8em;
  box-shadow: none;
}

.custom-table::-webkit-scrollbar-track {
  width: 0.8em;
  background-color: #f0f0f0; 
}

.custom-table::-webkit-scrollbar-track,
.custom-table::-webkit-scrollbar-thumb {
  border-radius: 12px;
}

.custom-table::-webkit-scrollbar-thumb {
  background-color: grey;
}

.min-w-100 {
  min-width: 100%;
}

.min-h-100 {
  min-height: 100%;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.tooltip-inner {
  background-color: black;
  color: white;
  border: none;
  font-weight: bold;
  text-align: start;
}

.btn-square {
  width:35px !important;
  height: 35px;
  border-radius: 5px;
}

main {
  justify-content: center;
}

main > .container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.popup-open-btn-left {
  border-radius: 0 8px 8px 0;
  padding-top: 15px;
  padding-bottom: 15px;
}