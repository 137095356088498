.dnv-table {
    max-width: 95vw;
    height: 70vh;
    overflow: auto;
}

.dnv-row .controls {
    position: sticky;
    z-index: 2;
    background-color: white;
}

.dnv-row .controls:nth-last-child(1) {
    right: 0px;
}
.btn-edit:enabled {
    width: 155px;
    z-index: 5;
  }

  .dnv-cell{
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3.6em; 
    line-height: 1.2em;
    max-lines: 2;
  }
  