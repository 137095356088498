.data-table {
    max-width: 75vw;
    overflow: auto;
    height: 59vh;
}

.ship-data-row :first-child {
    position: sticky;
    left: 0;
    z-index: 0;
    background-color: white !important;
}

.sticky-table-header{
    position: sticky;
    top:0;
    z-index: 1 !important;
}

@media (max-width: 992px) {
    .data-table {
        max-width: 100vw;
        height: calc(100vh - 250px);
    }
}

@media (max-width: 769px) {
    .data-table {
        height: calc(100vh - 340px);
    }
}