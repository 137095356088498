.format-selector {
    background-color: white !important;
    color: black !important;
    border-radius: 20px;
    border: none;
} 

.format-selector.active {
    background-color: blue !important;
    color: white !important;
}

.format-selector:hover {
    background-color: darkblue !important;
    color: white !important;
}