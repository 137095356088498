.meta-input,
.meta-input-tagName {
  border-style: hidden;
  border-radius: 10px;
  width: 150px;
}

.meta-input:enabled:hover {
  box-shadow: 0 0 0 0.05rem white, 0 0 0 0.15rem #258cfb;
  cursor: pointer;
}

.meta-input-tagName {
  width: 175px;
}

.meta-input-sfiGroup {
  width: 100px;
}

.meta-input-description {
  width: 250px;
}

.meta-input-description_no {
  width: 100px;
}

.meta-table {
  max-width: 95vw;
  height: 60vh;
  overflow: auto;
  border-radius: 5px;
}

.meta-table th {
  background-color: #dbdbdb !important;
}

.btn-edit:enabled {
  z-index: 5;
}
.tagmeta-row .col-auto:not(.controls) {
  min-width: 180px; 
}
.tagmeta-row .col-auto > * {
  margin: auto;
  width: fit-content !important;
}

.tagmeta-row .controls {
  position: sticky;
  right: 0;
  z-index: 2;
  background-color: white;
}

.tagmeta-row .controls:nth-last-child(3) {
  right: 88px;
}

.tagmeta-row .controls:nth-last-child(2) {
  right: 0px;
}

.editable-row-input{
  width: auto; 
  min-width: 10px; 
  box-sizing: content-box;
  border: none;
}

.meta-table .controls {
  box-shadow: 0 0 2px black;
}
 
span.disabled {
  color: #979797 !important;
  font-weight: 800;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.tagName {
  font-weight: 500;
  background-color: #e6e6e6 !important;
}

.MuiDialog-root {
  z-index: 1021 !important;
}