.view-popup-content {
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 100%;
    transition: max-width 0.3s;
    max-height: 85vh;
}

.view-popup-content.hide {
    width: 0;
}

.min-w-0 {
    min-width: 0;
    width: 0;
}
 