html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.nav-link {
  white-space: nowrap;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.dropdown-selector {
  background-color: white !important;
  color: black !important;
  border: none;
}

.dropdown-selector:focus {
  border: none;
  outline: none;
}

.dropdown-menu {
  z-index: 1500;
}

.nav-link:active {
  background-color: #0366d6;
  transition: color 0s;
  color: white !important;
}

.nav-link:hover {
  background-color: #3399ff !important;
  color: white !important;
}

nav .dropdown-item:active {
  background-color: transparent;
}

.nav-dropdown-link {
  border: none !important;
}

.nav-dropdown-link:hover {
  background-color: #b0b5c3 !important;
}
.nav-dropdown-link:active {
  background-color: #b0b5cf !important;
}